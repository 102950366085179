$color-mode-type: media-query;
$enable-negative-margins: true;
$enable-cssgrid: true;

$prefix: "";

$min-contrast-ratio: 3;

// Spacing
$spacer: 1rem;

$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 2,
  5: $spacer * 3,
  6: $spacer * 3.5,
  7: $spacer * 4,
  8: $spacer * 5,
);

$container-max-widths: (
  sm: 540px,
  jr: 720px,
  md: 960px,
  lg: 1280px,
  xl: 1440px,
  xxl: 1600px,
  xxxl: 2560px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  4xl: 2560px,
);

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "paper": calc(1.29 / 1 * 100%),
  "legal": calc(1.65 / 1 * 100%),
);

// Inputs
$input-placeholder-color: var(--text-faint);

// Transitions
$transition-timing: 125ms;
$transition-easing: ease-in-out;

// Buttons
$input-btn-padding-y: 0.25rem;
$input-btn-padding-x: 0.5rem;

$input-btn-padding-y-sm: 0.125rem;
$input-btn-padding-x-sm: 0.375rem;

$input-btn-padding-y-lg: 0.375rem;
$input-btn-padding-x-lg: 0.75rem;

// Shadows
$box-shadow:
  0.3px 0.3px 0.6px rgba($black, 0.024),
  0.9px 0.9px 1.8px rgba($black, 0.035),
  2.1px 2.1px 4.2px rgba($black, 0.046),
  7px 7px 14px rgba($black, 0.07);

$box-shadow-sm:
  0.2px 0.2px 0.3px rgba($black, 0.024),
  0.5px 0.5px 0.8px rgba($black, 0.035),
  1.2px 1.2px 1.8px rgba($black, 0.046),
  4px 4px 6px rgba($black, 0.07);

$box-shadow-lg:
  0.7px 0.7px 1.3px rgba($black, 0.024),
  1.9px 1.9px 3.6px rgba($black, 0.035),
  4.5px 4.5px 8.7px rgba($black, 0.046),
  15px 15px 29px rgba($black, 0.07);

$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);

// Borders
$border-radius: 0.38rem;
$border-radius-sm: 0.22rem;
$border-radius-lg: 0.42rem;

$font-family-sans-serif: "iA Writer Quattro S";
$font-family-mono: "iA Writer Mono S";
$headings-font-family: "iA Writer Quattro S";
$logo-font-family: "Noto Serif";

// Cards
$card-cap-bg: var(--surface-2);

// Accordions
$accordion-icon-width: 1rem;
$accordion-icon-color: $gray-600;
$accordion-button-padding-y: $spacer * 0.5;
$accordion-button-padding-x: $spacer;
$accordion-button-active-bg: var(--surface-1);

$offcanvas-horizontal-width: 240px;

$breadcrumb-divider-color: $gray-300;

$alert-margin-bottom: 0;

$list-group-active-bg: $gray-100;
$list-group-active-color: $primary;
$list-group-active-border-color: $gray-300;

$list-group-hover-bg: var(--surface-3);
$list-group-active-bg: var(--surface-3);
