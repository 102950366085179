.timeline {
  --timeline-spacing: 2rem;
  --timeline-inset: 9px;
  --timeline-rail-width: 2px;
  --timeline-indicator-height: 1.25rem;

  &--dense {
    --timeline-spacing: 1.25rem;
    --timeline-indicator-height: 0.75rem;

    font-size: $font-size-sm;
  }

  &__icon {
    width: calc(var(--timeline-inset) * 2 + var(--timeline-rail-width));
    min-width: calc(var(--timeline-inset) * 2 + var(--timeline-rail-width));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__entry {
    position: relative;

    &:not(:last-child) {
      margin-bottom: var(--timeline-spacing);

      &:after {
        display: block;
        content: "";
        height: var(--timeline-indicator-height);
        border-left: var(--timeline-rail-width) dotted $border-color;
        position: absolute;
        width: calc(var(--timeline-inset) * 2 + var(--timeline-rail-width));
        min-width: calc(var(--timeline-inset) * 2 + var(--timeline-rail-width));

        bottom: calc(
          ((var(--timeline-spacing) / 2) + var(--timeline-indicator-height)) *
            -1
        );

        transform: translateY(-50%)
          translateX(calc(50% - var(--timeline-rail-width) / 2));
      }
    }
  }
}
