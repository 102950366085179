.clean-tooltip {
  padding: 0 !important;
  opacity: 1 !important;

  .tooltip-arrow {
    display: none;
  }

  .tooltip-inner {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    text-align: inherit;
    color: inherit;
    max-width: none;
  }
}
