.review {
  --two-col-sidebar-width: 460px;

  &__sidebar {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__sidebar_header {
    padding: spacer(3) spacer(3) 0;
  }

  &__sidebar_content {
    flex: 1;
    overflow-y: auto;
    padding: spacer(3);
  }

  &__sidebar_footer {
    border-top: 1px solid var(--border-color);
    padding: spacer(3);
    background: rgba(var(--body-color-rgb), 0.03);
  }
}
