.grip {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-subtle);

  &:active,
  &:hover {
    color: var(--text-muted);
  }

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
}
