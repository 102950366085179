#login {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

#legal {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 100%;

  .sidenav {
    height: unset;
    min-width: 300px;
    justify-content: flex-start;
    padding-top: 50vh;
  }
}

.sidenav {
  height: 100%;
  width: 30%;
  min-width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $primary;
  color: $white;
  overflow-x: hidden;
  padding: $grid-gutter-width;
}

.main {
  padding: $grid-gutter-width;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login_form {
  width: 480px;
}

.legal_body {
  max-width: 80ch;
  font-size: 18px;
}
