.page_header {
  &__title {
    font-weight: 800;
    color: var(--emphasis-color);
    display: inline-flex;
    align-items: center;

    .badge {
      font-size: 0.5em;
    }
  }

  &__subtitle {
    color: var(--body-color);
  }
}
