.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: calc(#{$input-border-width} * 2) dashed $input-border-color;
  border-radius: $border-radius;
  padding: spacer(3);
  color: var(--text-muted);
  margin-bottom: spacer(3);
  cursor: pointer;

  p {
    margin: 0;
  }

  &:not(.dropzone--disabled):hover {
    border-color: $input-focus-border-color;
    color: $input-focus-color;
  }

  &.dropzone--disabled {
    opacity: 0.7;
    cursor: unset;
  }

  &--active {
    border-color: $blue;
    color: $blue;
  }

  &--accept {
    border-color: $success;
    color: $success;
  }

  &--reject {
    border-color: $danger;
    color: $danger;
  }
}

.dropzone--block {
  .dropzone {
    max-width: unset;
  }
}
