.faded_preview {
  position: relative;
  transition: height $transition-timing $transition-easing;

  &__container {
    position: relative;
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(transparent, var(--surface-2));
    z-index: 1;
    transition: opacity $transition-timing $transition-easing;
  }

  &__control {
    transition: opacity $transition-timing $transition-easing;
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%) translateY(50%);
    opacity: 0;
  }

  &__control--visible {
    opacity: 1;
  }

  &__overlay--invisible {
    opacity: 0;
  }

  &__overlay--visible {
    opacity: 1;
  }

  &--collapsed {
    overflow: hidden;
  }

  &--expanded,
  &--collapsing {
    overflow: auto;
  }
}
