.token {
  background-color: $yellow-100;
  color: black;
  padding: 2px;
  border: 1px solid $yellow-200;
  border-radius: 4px;
  transition: background-color $transition-easing $transition-timing;
  white-space: nowrap;
  text-overflow: ellipsis;

  &--fn {
    background-color: white;
    border: 1px solid $purple-300;
    color: $purple-300;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &.token--variable-present {
    margin-left: auto;
    margin-right: auto;
    background-color: $highlight;
    color: inherit;
    width: fit-content;
  }

  &__tab {
    position: absolute;
    left: 0;
    bottom: calc(100% + #{spacer(2)});
    padding: spacer(2);
    border-radius: var(--border-radius);
    background-color: inherit;
    font-size: 0.5em;
    border-color: inherit;
    border-width: 1px;
    border-style: solid;
    line-height: 1;
    opacity: 0.75;
  }
}

.document_editor {
  &__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);

    &.document_editor--readonly {
      background-color: var(--surface-4);
    }

    .toolbar,
    .footer {
      background: var(--surface-2);
      padding: spacer(2);

      &--secondary {
        background: var(--surface-3);
      }
    }

    .toolbar {
      border-bottom: 1px solid var(--border-color);
    }

    .footer {
      border-top: 1px solid var(--border-color);
    }

    .editor {
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      font-family: $font-family-sans-serif;
      padding: spacer(3);

      @include media-breakpoint-up(xxxl) {
        padding: spacer(5);
      }

      &--readonly {
        background: var(--secondary-bg);
        color: var(--secondary-color);
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .h1,
      .h2,
      .h3,
      .h4,
      .h5,
      .h6 {
        font-family: inherit;
        font-weight: 700;
        margin-bottom: 0.5em;
      }

      .ProseMirror {
        outline: 0;

        background: var(--body-bg);
        padding: spacer(5);
        box-shadow: var(--box-shadow-sm);
        border-radius: var(--border-radius);
        border: 1px solid var(--border-color);
        max-width: map-get($container-max-widths, lg);
        margin: auto;

        &-hideselection * {
          caret-color: transparent;
        }
      }

      .token {
        background-color: $yellow-100;
        border: 1px solid $yellow-200;
        color: black;
        padding: 2px;
        border-radius: 4px;

        &--fn {
          background-color: $purple-500;
          border-color: $purple-800;
          color: $purple-50;
        }

        &.token--variable-not-found {
          background-color: var(--warning-bg-subtle);
          color: var(--warning);
        }

        &.token--variable-present {
          background-color: $highlight;
          color: inherit;
        }

        &.token--variable-placeholder {
          font-weight: bold;
        }

        &.token--signature {
          background-color: $purple-100;
          border-color: $purple-300;
          color: $purple-900;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }

      .table {
        border: 1px solid var(--border-color);
        width: 100%;
        margin-bottom: spacer(3);

        th {
          background-color: var(--secondary-bg);
        }

        td,
        th {
          border: 1px solid var(--border-color);
          padding: spacer(1);
          position: relative;
          min-width: 1em;

          p {
            &:last-child {
              margin: 0;
            }
          }

          &.selectedCell {
            &::after {
              z-index: 2;
              position: absolute;
              content: "";
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              background: rgba($info, 0.15);
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

.schema_editor {
  &__stamp {
    cursor: grab;

    &:hover {
      cursor: grab;
    }

    &--active,
    &:active {
      cursor: grabbing;
    }
  }
}

.editor_dropzone {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  &--reject {
    border: 5px dashed $warning-border-subtle;
    background-color: rgba($warning-bg-subtle, $modal-backdrop-opacity);
    color: $warning-text-emphasis;
  }

  &--accept {
    border: 5px dashed $info-border-subtle;
    background-color: rgba($info-bg-subtle, $modal-backdrop-opacity);
    color: $info-text-emphasis;
  }
}
