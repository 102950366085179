.card {
  box-shadow: var(--box-shadow-sm);

  &.flat {
    box-shadow: none;
  }

  &.card-dense {
    .card-body {
      padding: spacer(2);
    }

    .card-footer,
    .card-header {
      padding-left: spacer(2);
      padding-right: spacer(2);
    }
  }

  &.card-compact {
    .card-body,
    .card-header,
    .card-footer {
      padding: spacer(1);
    }
  }

  > .table {
    margin-bottom: 0;
    overflow: hidden;

    tr:last-child {
      td,
      th {
        border-bottom-width: 0;
      }
    }

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }

    &:last-child {
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }
}

.simple_card_header {
  background-color: var(--card-bg);
  padding: calc(var(--card-spacer-y) / 2) var(--card-spacer-x);

  &--dense {
    border-bottom: 0;
    padding-top: spacer(2);
    padding-bottom: spacer(2);
  }

  &.card-action:disabled,
  &.card-action.disabled {
    background-color: var(--gray-100);
  }
}

.card-title {
  font-size: $font-size-sm;
  font-weight: 700;
  margin-bottom: 0;
  font-family: var(--body-font-family);
}

.card-action {
  &:hover {
    color: var(--link-color);
    cursor: pointer;
  }
  &.disabled,
  &:disabled {
    &:hover {
      color: inherit;
      cursor: inherit;
    }
  }
}
