.project-column {
  background-color: var(--surface-3);
  border-radius: $border-radius;

  .project-column__header {
    display: flex;
    flex-direction: row;
    transition: opacity $transition-timing $transition-easing;
    opacity: 0.5;
  }

  &:hover {
    box-shadow: var(--box-shadow-sm);

    .project-column__header {
      opacity: 1;
    }
  }
}

.project_tile {
  transition:
    opacity $transition-timing $transition-easing,
    background-color $transition-timing $transition-easing;
  background-color: var(--surface-1);
  opacity: 0.85;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    margin: 0;
  }

  &__content {
    padding: spacer(2);
  }

  &__footer {
    padding: spacer(2);
    font-size: $font-size-sm;
    border-top: 1px solid var(--border-color);
  }

  .project_tile__actions {
    opacity: 0.5;
    display: flex;
    flex-direction: row;
    gap: spacer(1);
  }

  &.project_tile--clickable {
    cursor: pointer;

    &:not(.project_tile--loading):hover {
      background-color: var(--surface-3);
    }
  }

  &:not(.project_tile--loading):hover {
    box-shadow: var(--box-shadow-sm);
    opacity: 1;

    .project_tile__actions {
      opacity: 1;
    }
  }

  @each $name, $color in $theme-colors {
    &.project_tile--#{$name} {
      background-color: var(--#{$name}-bg-subtle);
      color: var(--#{$name}-text-emphasis);

      &:not(.project_tile--loading):hover {
        background-color: var(--#{$name}-bg-subtle);
      }
    }
  }

  &.project_tile--loading {
    opacity: 0.5;
  }
}
