body,
html,
#__next {
  height: 100%;
  overflow: hidden;
}

.app {
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "alert alert"
    "sidebar main";

  &__alert {
    grid-area: alert;
  }

  &__sidebar {
    background-color: var(--surface-2);
    grid-area: sidebar;
    overflow: auto;
    width: 250px;
    padding: $grid-gutter-width;
    border-right: 1px solid var(--border-color);
  }

  &__content {
    grid-area: main;
    overflow: auto;
    overflow-y: auto;
    max-width: none;
    position: relative;
    padding: $grid-gutter-width;

    &--flush {
      padding: 0;
    }
  }
}

.app_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 54px;

  &__menu {
    border-radius: 0 !important;
    height: 100%;
    width: 54px;
    min-width: 54px;

    border-right: 1px solid var(--border-color);

    &:hover {
      border-right: 1px solid var(--border-color);
    }

    &--right {
      border-right: 0;
      border-left: 1px solid var(--border-color);

      &:hover {
        border-right: 0;
        border-left: 1px solid var(--border-color);
      }
    }
  }

  &__content {
    flex: 1;
    // overflow: hidden;
    padding-left: spacer(2);
    padding-right: spacer(2);

    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  &__start_button,
  &__end_button {
    flex: 1;
    flex-shrink: 1;
    width: 54px;
    height: 100%;
    min-width: 54px;
    max-width: 54px;
  }
}
