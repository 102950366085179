$muted_opacity: 0.75;
$subtle_opacity: 0.5;
$faint_opacity: 0.25;

// scss-docs-start gray-color-variables
$white: #fff;
$gray-50: #fcfafa;
$gray-100: #f7f5f5;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #2b2a32;
$gray-950: #1c1b21;
$gray-1000: #071117;

$black: #000;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
$gray: $gray-500;
$green: #557942;
$yellow: #f7bd3e;
$orange: #e58741;
$red: #cb3625;
$pink: #cd6899;
$purple: #7b5cc8;
$primary: #3f51b5;
$blue: #1fa6e0;
// scss-docs-end color-variables

// fusv-disable
$blue-50: #d2effc;
$blue-100: #bde7fa;
$blue-200: #95d7f4;
$blue-300: #6cc7ef;
$blue-400: #42b8eb;
$blue-500: #1fa6e0;
$blue-600: #1a8bbc;
$blue-700: #0c74a1;
$blue-800: #09597b;
$blue-900: #063e55;

$purple-50: #dad4f1;
$purple-100: #cfc7ec;
$purple-200: #b9ace3;
$purple-300: #a491da;
$purple-400: #8f77d1;
$purple-500: #7b5cc8;
$purple-600: #6b4ead;
$purple-700: #5a4092;
$purple-800: #482c7e;
$purple-900: #38255c;

$pink-50: #efcfdf;
$pink-100: #ebc3d7;
$pink-200: #e3acc8;
$pink-300: #dc95b8;
$pink-400: #d47ea9;
$pink-500: #cd6899;
$pink-600: #b15080;
$pink-700: #953966;
$pink-800: #702b4d;
$pink-900: #4e1a34;

$red-50: #f3bab4;
$red-100: #f1aaa2;
$red-200: #eb8a7f;
$red-300: #e66a5c;
$red-400: #d94f3f;
$red-500: #bf3f31;
$red-600: #aa3427;
$red-700: #912d22;
$red-800: #7b2319;
$red-900: #641911;

$orange-50: #ffdfc5;
$orange-100: #ffd7b6;
$orange-200: #ffc597;
$orange-300: #ffb479;
$orange-400: #ffa25a;
$orange-500: #e58741;
$orange-600: #c66e2d;
$orange-700: #9e5622;
$orange-800: #773e16;
$orange-900: #4b280f;

$yellow-50: #fce3ac;
$yellow-100: #fbdea0;
$yellow-200: #fad687;
$yellow-300: #f9ce6f;
$yellow-400: #f8c556;
$yellow-500: #f7bd3e;
$yellow-600: #dda220;
$yellow-700: #ab7e1a;
$yellow-800: #7a5a12;
$yellow-900: #48360c;

$green-50: #cddec3;
$green-100: #bfd5b2;
$green-200: #a4c391;
$green-300: #88b170;
$green-400: #6d9a54;
$green-500: #557942;
$green-600: #496939;
$green-700: #3d5830;
$green-800: #314827;
$green-900: #26371e;

$primary-50: #a0a8de;
$primary-100: #949eda;
$primary-200: #7e8ad2;
$primary-300: #6776ca;
$primary-400: #5061c2;
$primary-500: #3f51b5;
$primary-600: #37479e;
$primary-700: #2f3d88;
$primary-800: #273371;
$primary-900: #1f295a;

$grays: (
  gray: $gray,
  gray-50: $gray-50,
  gray-100: $gray-100,
  gray-200: $gray-200,
  gray-300: $gray-300,
  gray-400: $gray-400,
  gray-500: $gray-500,
  gray-600: $gray-600,
  gray-700: $gray-700,
  gray-800: $gray-800,
  gray-900: $gray-900,
  gray-950: $gray-950,
  gray-1000: $gray-1000,
);

$blues: (
  blue: $blue,
  blue-50: $blue-50,
  blue-100: $blue-100,
  blue-200: $blue-200,
  blue-300: $blue-300,
  blue-400: $blue-400,
  blue-500: $blue-500,
  blue-600: $blue-600,
  blue-700: $blue-700,
  blue-800: $blue-800,
  blue-900: $blue-900,
);

$purples: (
  purple: $purple,
  purple-50: $purple-50,
  purple-100: $purple-100,
  purple-200: $purple-200,
  purple-300: $purple-300,
  purple-400: $purple-400,
  purple-500: $purple-500,
  purple-600: $purple-600,
  purple-700: $purple-700,
  purple-800: $purple-800,
  purple-900: $purple-900,
);

$pinks: (
  pink: $pink,
  pink-50: $pink-50,
  pink-100: $pink-100,
  pink-200: $pink-200,
  pink-300: $pink-300,
  pink-400: $pink-400,
  pink-500: $pink-500,
  pink-600: $pink-600,
  pink-700: $pink-700,
  pink-800: $pink-800,
  pink-900: $pink-900,
);

$reds: (
  red: $red,
  red-50: $red-50,
  red-100: $red-100,
  red-200: $red-200,
  red-300: $red-300,
  red-400: $red-400,
  red-500: $red-500,
  red-600: $red-600,
  red-700: $red-700,
  red-800: $red-800,
  red-900: $red-900,
);

$oranges: (
  orange: $orange,
  orange-50: $orange-50,
  orange-100: $orange-100,
  orange-200: $orange-200,
  orange-300: $orange-300,
  orange-400: $orange-400,
  orange-500: $orange-500,
  orange-600: $orange-600,
  orange-700: $orange-700,
  orange-800: $orange-800,
  orange-900: $orange-900,
);

$yellows: (
  yellow: $yellow,
  yellow-50: $yellow-50,
  yellow-100: $yellow-100,
  yellow-200: $yellow-200,
  yellow-300: $yellow-300,
  yellow-400: $yellow-400,
  yellow-500: $yellow-500,
  yellow-600: $yellow-600,
  yellow-700: $yellow-700,
  yellow-800: $yellow-800,
  yellow-900: $yellow-900,
);

$greens: (
  green: $green,
  green-50: $green-50,
  green-100: $green-100,
  green-200: $green-200,
  green-300: $green-300,
  green-400: $green-400,
  green-500: $green-500,
  green-600: $green-600,
  green-700: $green-700,
  green-800: $green-800,
  green-900: $green-900,
);

$primaries: (
  primary: $primary,
  primary-50: $primary-50,
  primary-100: $primary-100,
  primary-200: $primary-200,
  primary-300: $primary-300,
  primary-400: $primary-400,
  primary-500: $primary-500,
  primary-600: $primary-600,
  primary-700: $primary-700,
  primary-800: $primary-800,
  primary-900: $primary-900,
);

// fusv-enable

// scss-docs-start theme-color-variables
$secondary: $gray-300;
$success: $green;
$info: $blue;
$warning: $orange;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
// scss-docs-end theme-color-variables

// scss-docs-start colors-map
$colors: (
  blue: $blue,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  black: $black,
  white: $white,
  primary: $primary,
  gray: $gray-500,
  gray-dark: $gray-800,
  info: $info,
  secondary: $secondary,
);
// scss-docs-end colors-map

// scss-docs-start theme-colors-map
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
);
// scss-docs-end theme-colors-map

$color_collections: (
  $greens,
  $grays,
  $yellows,
  $oranges,
  $reds,
  $pinks,
  $purples,
  $blues,
  $primaries
);

// scss-docs-start theme-text-variables
$primary-text-emphasis: $primary-700;
$secondary-text-emphasis: $gray-700;
$success-text-emphasis: $green-700;
$info-text-emphasis: $blue-700;
$warning-text-emphasis: $orange-700;
$danger-text-emphasis: $red-700;
$light-text-emphasis: $gray-700;
$dark-text-emphasis: $gray-700;
// scss-docs-end theme-text-variables

// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle: $primary-50;
$secondary-bg-subtle: $gray-50;
$success-bg-subtle: $green-50;
$info-bg-subtle: $blue-50;
$warning-bg-subtle: $orange-50;
$danger-bg-subtle: $red-50;
$light-bg-subtle: $gray-50;
$dark-bg-subtle: $gray-400;
// scss-docs-end theme-bg-subtle-variables

// scss-docs-start theme-border-subtle-variables
$primary-border-subtle: $primary-200;
$secondary-border-subtle: $gray-200;
$success-border-subtle: $green-200;
$info-border-subtle: $blue-200;
$warning-border-subtle: $orange-200;
$danger-border-subtle: $red-200;
$light-border-subtle: $gray-200;
$dark-border-subtle: $gray-500;
// scss-docs-end theme-border-subtle-variables

$surface-1: $white;
$surface-2: $gray-50;
$surface-3: $gray-100;
$surface-4: $gray-200;

$surface-1-dark: $gray-1000;
$surface-2-dark: $gray-950;
$surface-3-dark: $gray-900;
$surface-4-dark: $gray-800;

$body-bg: $surface-1;
$body-secondary-bg: $surface-2;
$body-tertiary-bg: $surface-3;
$body-bg-dark: $surface-1-dark;
$body-secondary-bg-dark: $surface-2-dark;
$body-tertiary-bg-dark: $surface-3-dark;

$border-color: $gray-300;
$border-color-dark: $gray-700;

$body-color: $gray-900;
$body-color-dark: $gray-400;
$body-emphasis-color: $black;

$body-secondary-color: rgba($body-color, $muted_opacity);
$body-tertiary-color: rgba($body-color, $subtle_opacity);
$body-faint-color: rgba($body-color, $faint_opacity);

$body-secondary-color-dark: rgba($body-color-dark, $muted_opacity);
$body-tertiary-color-dark: rgba($body-color-dark, $subtle_opacity);
$body-faint-color-dark: rgba($body-color-dark, $faint_opacity);

$text-muted: $body-secondary-color;
$text-subtle: $body-tertiary-color;
$text-faint: $body-faint-color;

$text-muted-dark: $body-secondary-color-dark;
$text-subtle-dark: $body-tertiary-color-dark;
$text-faint-dark: $body-faint-color-dark;

$highlight: #fff9ed;

:root {
  --surface-1: #{$surface-1};
  --surface-2: #{$surface-2};
  --surface-3: #{$surface-3};
  --surface-4: #{$surface-4};

  --text-muted: #{$text-muted};
  --text-subtle: #{$text-subtle};
  --text-faint: #{$text-faint};
  --text-inverse: #{$body-color-dark};

  @each $collection in $color_collections {
    @each $name, $color in $collection {
      --#{$name}: #{$color};
    }
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --surface-1: #{$surface-1-dark};
    --surface-2: #{$surface-2-dark};
    --surface-3: #{$surface-3-dark};
    --surface-4: #{$surface-4-dark};

    --text-muted: #{$text-muted-dark};
    --text-subtle: #{$text-subtle-dark};
    --text-faint: #{$text-faint-dark};
    --text-inverse: #{$body-color};
  }
}
