.icon {
  display: inline-flex;
  align-self: center;
  vertical-align: baseline;

  svg {
    height: 1em;
    width: 1em;
  }
}
