.btn-group {
  white-space: nowrap;
}

.btn {
  white-space: nowrap;

  &:hover {
    box-shadow: var(--box-shadow-sm);
  }

  &.btn-secondary {
    background-color: var(--surface-2);
    color: var(--secondary-color);
    &,
    &:active {
      border-color: var(--border-color);
    }

    &:active {
      background-color: var(--surface-4);
    }
  }

  &.btn-block {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &.btn-muted {
    opacity: $subtle_opacity;
    transition: opacity $transition-timing $transition-easing;
    color: var(--body-color);
    border: none;

    &:hover {
      opacity: 1;
    }
  }

  &.btn-outline-secondary {
    @extend .btn-secondary;
    background-color: transparent;
  }

  &.btn-link,
  &.btn-icon,
  &.btn-muted {
    &:hover {
      box-shadow: none;
    }
  }

  &.btn-icon {
    background-color: var(--surface-1);
    border-radius: 999rem;

    &:hover {
      background-color: var(--surface-2);
    }

    &:active,
    &.active,
    &.show {
      border-color: transparent;
    }

    &:active {
      background-color: var(--surface-3);
    }

    &.dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }

  &.btn-fab {
    background-color: var(--surface-1);
    border-color: var(--border-color);

    border-radius: 999rem;

    &:hover {
      background-color: var(--surface-2);
    }
  }

  @include offset-icon;
}

.btn-xs {
  @include button-size(
    $badge-padding-y,
    $badge-padding-x,
    $badge-font-size,
    $badge-border-radius
  );
  line-height: 1;
  vertical-align: baseline;
}

.spinner-border-xs {
  width: $spinner-width-sm * 0.75;
  height: $spinner-height-sm * 0.75;
  border-width: $spinner-border-width-sm * 0.75;
}

.btn.btn-choose-entity {
  width: 100%;
  border: 2px dashed var(--border-color);

  &:hover {
    background-color: var(--surface-2);
  }

  &:focus {
    outline: auto;
  }

  &:disabled {
    background-color: var(--surface-2);
  }
}

.list-group-item.disabled {
  background-color: var(--gray-100);
}

.dropdown-toggle.dropdown--no-caret {
  &::after {
    display: none;
  }
}
