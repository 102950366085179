.logotype {
  line-height: 1;
  text-transform: lowercase;
  font-family: $logo-font-family;
  font-weight: 700;
  font-size: $h3-font-size;
  color: var(--primary);
  text-decoration: none;

  &--white {
    color: $white;
  }

  &--sm {
    font-size: $h4-font-size;
  }
}

.logo_link {
  text-decoration: none;

  > .logotype {
    display: inline-block;
    transition:
      opacity $transition-timing $transition-easing,
      transform $transition-timing $transition-easing;
  }

  &:hover {
    > .logotype {
      opacity: 0.75;
    }
  }

  &:active {
    > .logotype {
      transform: translateY(2px);
    }
  }
}
