.alert {
  &.alert-primary {
    background-color: $body-bg;

    background-image: radial-gradient(
      rgba($primary-bg-subtle, 0.1),
      rgba($primary-bg-subtle, 0.2)
    );

    border-color: $primary-border-subtle;
  }
}
