blockquote {
  color: $text-muted;
  padding-left: spacer(2);
  border-left: 2px solid var(--border-color);
  margin-bottom: $blockquote-margin-y;
}

time {
  cursor: default;
  border-bottom: 1px dotted color-mix(in srgb, currentColor 50%, transparent);
}

.alert,
.list-group-item,
.comment__content {
  *:last-child {
    margin-bottom: 0;
  }
}

strong {
  font-weight: bold;
}

.section_header {
  font-size: $font-size-base;
  font-weight: $font-weight-bolder;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: spacer(1);
  margin-bottom: spacer(3);
}
