.chip {
  --chip-font-size: #{$badge-font-size};

  font-size: var(--chip-font-size);
  padding: 0 spacer(1);
  font-family: var(--font-monospace);
  display: inline-block;
  border: 1px solid transparent;

  @include border-radius($border-radius-sm);

  @each $name, $color in $colors {
    &--#{$name} {
      color: var(--#{$name});
      border-color: var(--#{$name}-300);
    }
  }
}

button.chip {
  background-color: transparent;

  &.chip--secondary {
    &:hover {
      background-color: var(--secondary-bg);
      border-color: var(--secondary);
    }
  }
}
