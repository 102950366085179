.comment {
  &__header,
  &__content,
  &__footer {
    padding-left: spacer(3);
    padding-right: spacer(3);
  }

  &__header {
    padding-top: spacer(2);
    padding-bottom: spacer(1);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__header_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  &__header_right {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &__commenter {
    font-weight: bold;
  }

  &__permalink {
    font-size: $font-size-sm;
    color: var(--gray-600);
    text-decoration: none;

    &:hover {
      color: var(--primary-500);
    }
  }

  &__content {
    // avatar size + space between avatar and commenter name;
    // avatar size is hardcoded in the Comment component in JS

    *:last-child {
      margin-bottom: 0;
    }

    padding-top: spacer(3);
    padding-bottom: spacer(3);

    .comment__header ~ & {
      margin-left: calc(#{spacer(2)} + 16px);
      padding-top: spacer(1);
    }
  }

  &__tasks {
    padding: spacer(1) spacer(3);
  }

  &__new_task {
    padding: spacer(2) spacer(3);
  }
}
