ins.insert {
  display: inline-block;
  background-color: var(--success-bg-subtle);
  color: var(--success-text-emphasis);
}

del.delete {
  display: inline-block;
  background-color: var(--danger-bg-subtle);
  color: var(--danger-text-emphasis);
}

span.move {
  display: inline-block;
  background-color: var(--warning-bg-subtle);
  color: var(--warning-text-emphasis);
}
