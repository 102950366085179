.two_col {
  --two-col-sidebar-width: 340px;

  height: 100dvh;
  width: 100dvw;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "main sidebar"
    "footer footer";

  &__main {
    background: var(--secondary-bg);
    grid-area: main;
    overflow: hidden;
    max-width: none;
    width: 100%;
  }

  &__sidebar {
    width: var(--two-col-sidebar-width);
    overflow: auto;
    background-color: var(--surface-2);
    border-left: 1px solid var(--border-color);
  }

  &__sidebar_wrapper {
    height: 100%;
    overflow-y: auto;
  }

  &__resizer {
    width: 16px;
    margin-right: -14px;
    position: relative;
    border-left: 2px solid var(--border-color);
    transition: border-left ease-in-out 0.25s;

    &:hover,
    &:active {
      border-left: 2px solid var(--primary);
    }

    &--collapsed {
      margin-right: 0;
    }
  }

  &__resizer_handle {
    position: absolute;
    bottom: spacer(1);
    box-shadow: var(--box-shadow-sm);
    z-index: 999;
    transform: translate(-50%, -50%);

    &:hover {
      border-color: var(--primary);
    }
  }

  &__header {
    grid-area: header;
    flex-shrink: 1;
    background-color: var(--surface-2);
    border-bottom: 1px solid var(--border-color);
  }

  &__footer {
    grid-area: footer;
    flex-shrink: 1;
  }

  &--with-header {
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "header header"
      "main main"
      "footer footer";
  }
}
