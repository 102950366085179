.overlap_stack {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: spacer(1) / 2;
  > * {
    transition: transform 0.25s ease-in-out;
  }

  @for $i from 1 to 12 {
    > :nth-child(#{$i}) {
      transform: translateX(calc((-40% - #{spacer(1) / 2}) * ($i - 1)));
    }
  }

  &:hover {
    > * {
      transform: translateX(0);
    }
  }
}
