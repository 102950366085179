.editor {
  .block {
    border-radius: 5px;
    background-color: white;
    color: $purple-300;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .block--signature {
    border: 1px solid $purple-500;
    height: 150px;
    font-size: $font-size-lg;
    margin-bottom: spacer(3);
  }

  .block--pagebreak {
    height: 30px;
    margin-bottom: 150px;
  }
}
