@mixin editor-variant($color, $color-100, $color-300, $color-900) {
  --editor-theme-color: #{$color};
  --editor-theme-color-100: #{$color-100};
  --editor-theme-color-300: #{$color-300};
  --editor-theme-color-900: #{$color-900};

  .btn-editor {
    @include button-variant($color, $color);
  }

  .btn-outline-editor {
    @include button-outline-variant($color);
  }
}

.editor {
  $save_indicator_size: 12px;

  @include editor-variant($gray, $gray-100, $gray-300, $gray-900);

  .app_header {
    border-top: 4px solid var(--editor-theme-color);
  }

  &__footer {
    border-top: 1px solid var(--editor-theme-color-300);
    background-color: var(--editor-theme-color-100);
    color: var(--editor-theme-color-900);
  }

  &__editor_type_flag {
    background-color: var(--editor-theme-color-300);
    color: var(--white);
  }

  &__sidebar {
    padding: spacer(2);

    @include media-breakpoint-down(sm) {
      padding: spacer(1);
    }
  }

  &__save_indicator {
    width: $save_indicator_size;
    height: $save_indicator_size;
    border-radius: $save_indicator_size;
    background-color: var(--editor-theme-color);
  }

  &--document {
    @include editor-variant($primary, $primary-100, $primary-300, $primary-900);
  }

  &--template {
    @include editor-variant($pink, $pink-100, $pink-300, $pink-900);
  }
}

/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: var(--border-radius) var(--border-radius) var(--border-radius)
    0;
  color: var(--gray-900);
  font-size: $font-size-sm;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0 spacer(1);
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
}

.drag-handle {
  position: fixed;
  opacity: 1;
  transition: opacity ease-in 0.2s;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='fill: rgba(0, 0, 0, 0.5)'%3E%3Cpath d='M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: calc(0.5em + 0.375rem) calc(0.5em + 0.375rem);
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.5rem;
  z-index: $zindex-tooltip;

  @extend .grip;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  @include media-breakpoint-down(sm) {
    display: none;
    pointer-events: none;
  }

  @media (prefers-color-scheme: dark) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='fill: rgba(255, 255, 255, 0.5)'%3E%3Cpath d='M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z'%3E%3C/path%3E%3C/svg%3E");
  }
}

.tiptap {
  .has-focus {
    background-color: rgba(0, 0, 0, 0.01);

    @media (prefers-color-scheme: dark) {
      background-color: rgba(255, 255, 255, 0.01);
    }
  }

  p.is-empty.has-focus::before {
    color: var(--text-subtle);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
