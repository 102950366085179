.filter_menu_dropdown {
  background-color: var(--surface-2);
  padding: 0;
  overflow: hidden;
}

.filter_menu {
  background-color: var(--surface-2);
  padding: 0;
  min-width: 260px;
  overflow: hidden;

  border: 1px solid var(--light-border-subtle);
  border-radius: var(--border-radius);

  &__search {
    padding: spacer(2);
    border-bottom: 1px solid var(--light-border-subtle);
  }

  &__list {
    background-color: var(--body-bg);
    overflow-y: auto;
    max-height: 200px;
  }

  &__footer {
    border-top: 1px solid var(--light-border-subtle);
    padding: spacer(2);
  }

  &--flush {
    border: 0;
    border-radius: unset;
  }
}
