.nav-pills {
  gap: spacer(2);

  &.bordered {
    padding-bottom: spacer(2);
    border-bottom: 1px solid var(--border-color);

    .nav-link.active,
    .show > .nav-link {
      position: relative;

      &:after {
        display: block;
        content: "";
        position: absolute;
        bottom: calc(#{spacer(2) * -1} - 1.5px);
        left: 0;
        right: 0;
        border-bottom: 2px solid var(--primary-300);
      }
    }
  }

  .nav-link {
    padding: spacer(1) spacer(2);
    color: var(--body-color);

    &:disabled {
      color: var(--nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent;
    }

    &:hover {
      background-color: var(--surface-3);
    }
  }

  .nav-link.active,
  .show > .nav-link {
    color: var(--body-color);

    font-weight: $font-weight-semibold;
    background-color: var(--surface-3);
  }

  &.nav-pills-primary {
    .nav-link.active,
    .show > .nav-link {
      color: var(--primary);

      font-weight: $font-weight-semibold;
      background-color: var(--surface-3);
    }
  }
}
