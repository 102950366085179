.popover-footer {
  @extend .popover-header;

  border-bottom: none;
  border-top: var(--#{$prefix}popover-border-width) solid
    var(--#{$prefix}popover-border-color);

  @include border-top-radius(0);
  @include border-bottom-radius(var(--#{$prefix}popover-inner-border-radius));
}
