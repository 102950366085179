.react-select {
  --input-bg: var(--body-bg);
  --input-border: var(--border-color);

  .react-select__control {
    min-height: auto;
    font-family: $form-select-font-family;
    @include font-size($form-select-font-size);

    background-color: var(--input-bg);
    border-color: var(--input-border);

    @include border-radius($form-select-border-radius, 0);
    @include transition($form-select-transition);

    &:focus,
    &.react-select__control--is-focused {
      border-color: $form-select-focus-border-color;
      border-width: $input-border-width;
      outline: 0;

      box-shadow: $form-select-box-shadow, $form-select-focus-box-shadow;
    }

    &.react-select__control--is-disabled,
    &:disabled {
      color: var(--text-subtle);
      background-color: $form-select-disabled-bg;
      border-color: $form-select-disabled-border-color;
    }

    // Remove outline from select box in FF
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $form-select-color;
    }
  }

  .react-select__input-container {
    margin: 0;
    padding: 0;
  }

  .react-select__value-container {
    padding: $form-select-padding-y $form-select-indicator-padding
      $form-select-padding-y $form-select-padding-x;
  }

  .react-select__indicator {
    padding: $form-select-padding-y $form-select-padding-x;
  }

  .react-select__menu {
    border-radius: var(--border-radius);
    background-color: var(--input-bg);
    border: 1px solid var(--input-border);
    overflow: hidden;

    box-shadow: var(--box-shadow);
  }

  .react-select__option {
    background-color: var(--input-bg);
    color: var(--body-color);

    transition:
      background-color $transition-timing $transition-easing,
      color $transition-timing $transition-easing;

    &:hover:not(.react-select__option--is-selected),
    &.react-select__option--is-focused:not(.react-select__option--is-selected) {
      background-color: var(--surface-3);
    }

    &.react-select__option--is-disabled:not(
        .react-select__option--is-selected
      ) {
      color: var(--text-muted);
      background-color: $form-select-disabled-bg;
      border-color: $form-select-disabled-border-color;
      pointer-events: none;
    }

    &.react-select__option--is-selected {
      background-color: var(--primary-100);
      color: var(--text-inverse);

      &.react-select__option--is-disabled {
        opacity: 0.5;
      }

      &:hover,
      &.react-select__option--is-focused {
        background-color: var(--primary-200);
      }
    }
  }

  .react-select__indicator-separator {
    background-color: var(--input-border);
  }

  .react-select__placeholder {
    color: var(--text-muted);
  }

  .react-select__single-value {
    color: var(--body-color);
  }

  &.react-select-lg {
    .react-select__control {
      @include font-size($form-select-font-size-lg);
      @include border-radius($form-select-border-radius-lg);
    }

    .react-select__indicator {
      padding: $form-select-padding-y-lg $form-select-padding-x-lg;
    }

    .react-select__value-container {
      padding-top: $form-select-padding-y-lg;
      padding-bottom: $form-select-padding-y-lg;
      padding-left: $form-select-padding-x-lg;
    }
  }

  &.react-select-sm {
    .react-select__control {
      @include font-size($form-select-font-size-sm);
      @include border-radius($form-select-border-radius-sm);
    }

    .react-select__indicator {
      padding: $form-select-padding-y-sm $form-select-padding-x-sm;
    }

    .react-select__value-container {
      padding-top: $form-select-padding-y-sm;
      padding-bottom: $form-select-padding-y-sm;
      padding-left: $form-select-padding-x-sm;
    }
  }
}
