@font-face {
  font-family: "iA Writer Mono S";
  src: url("../fonts/ia-mono/iAWriterMonoS-Bold.woff2") format("woff2"),
    url("../fonts/ia-mono/iAWriterMonoS-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Mono S";
  src: url("../fonts/ia-mono/iAWriterMonoS-BoldItalic.woff2") format("woff2"),
    url("../fonts/ia-mono/iAWriterMonoS-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Mono S";
  src: url("../fonts/ia-mono/iAWriterMonoS-Italic.woff2") format("woff2"),
    url("../fonts/ia-mono/iAWriterMonoS-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Mono S";
  src: url("../fonts/ia-mono/iAWriterMonoS-Italic.woff2") format("woff2"),
    url("../fonts/ia-mono/iAWriterMonoS-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Mono S";
  src: url("../fonts/ia-mono/iAWriterMonoS-BoldItalic.woff2") format("woff2"),
    url("../fonts/ia-mono/iAWriterMonoS-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Mono S";
  src: url("../fonts/ia-mono/iAWriterMonoS-Regular.woff2") format("woff2"),
    url("../fonts/ia-mono/iAWriterMonoS-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Mono S";
  src: url("../fonts/ia-mono/iAWriterMonoS-Bold.woff2") format("woff2"),
    url("../fonts/ia-mono/iAWriterMonoS-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Mono S";
  src: url("../fonts/ia-mono/iAWriterMonoS-Regular.woff2") format("woff2"),
    url("../fonts/ia-mono/iAWriterMonoS-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Quattro S";
  src: url("../fonts/ia-quattro/iAWriterQuattroS-BoldItalic.woff2")
      format("woff2"),
    url("../fonts/ia-quattro/iAWriterQuattroS-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Quattro S";
  src: url("../fonts/ia-quattro/iAWriterQuattroS-Bold.woff2") format("woff2"),
    url("../fonts/ia-quattro/iAWriterQuattroS-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Quattro S";
  src: url("../fonts/ia-quattro/iAWriterQuattroS-Bold.woff2") format("woff2"),
    url("../fonts/ia-quattro/iAWriterQuattroS-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Quattro S";
  src: url("../fonts/ia-quattro/iAWriterQuattroS-Italic.woff2") format("woff2"),
    url("../fonts/ia-quattro/iAWriterQuattroS-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Quattro S";
  src: url("../fonts/ia-quattro/iAWriterQuattroS-Italic.woff2") format("woff2"),
    url("../fonts/ia-quattro/iAWriterQuattroS-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Quattro S";
  src: url("../fonts/ia-quattro/iAWriterQuattroS-Regular.woff2") format("woff2"),
    url("../fonts/ia-quattro/iAWriterQuattroS-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Quattro S";
  src: url("../fonts/ia-quattro/iAWriterQuattroS-Regular.woff2") format("woff2"),
    url("../fonts/ia-quattro/iAWriterQuattroS-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Quattro S";
  src: url("../fonts/ia-quattro/iAWriterQuattroS-BoldItalic.woff2")
      format("woff2"),
    url("../fonts/ia-quattro/iAWriterQuattroS-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

/* noto-serif-regular - latin */
@font-face {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../fonts/noto-serif-v21-latin/noto-serif-v21-latin-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/noto-serif-v21-latin/noto-serif-v21-latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-serif-700 - latin */
@font-face {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../fonts/noto-serif-v21-latin/noto-serif-v21-latin-700.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/noto-serif-v21-latin/noto-serif-v21-latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
