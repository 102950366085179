.rich_text_area {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);

  &__toolbar {
    padding: spacer(2);
    background-color: var(--surface-3);
    border-bottom: 1px solid var(--border-color);
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
  }

  &__editor {
    padding: spacer(2);
    cursor: text;

    background-color: var(--surface-1);
    overflow-y: auto;

    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);

    .ProseMirror {
      &:focus {
        outline: none;
      }

      *:last-child {
        margin-bottom: 0;
      }
    }

    &:disabled,
    &.disabled {
      background-color: var(--surface-2);
    }
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: var(--secondary-color);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.react-datepicker-wrapper {
  width: 100%;
  display: block;

  input {
    @extend .form-control;
  }
}
