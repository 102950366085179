.action_card {
  overflow: hidden;
  position: relative;

  &--action {
    transition: box-shadow $transition-timing $transition-easing;

    &:hover {
      box-shadow: var(--box-shadow);
      cursor: pointer;
    }

    &:active {
      box-shadow: none;
    }
  }
}
