.floating_panel {
  position: fixed;
  bottom: 0;
  right: spacer(3);
  z-index: $zindex-offcanvas;
  min-width: 480px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &__content {
    max-height: 360px;
    overflow-y: auto;
  }
}
