.pdf_viewer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: spacer(3);

  background-color: var(--surface-4);

  &__doc {
    height: 100%;
  }

  &__page {
    overflow: hidden;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    height: 100%;
  }

  &__pagination {
    position: absolute;
    bottom: spacer(1);
    left: 50%;
    transform: translateX(-50%);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
  }
}
