/* signatures */

@font-face {
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/signature_dancing_script/DancingScript-VariableFont_wght.ttf")
    format(opentype);
}

@font-face {
  font-family: "Handlee";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/signature_handlee/Handlee-Regular.ttf") format(opentype);
}

@font-face {
  font-family: "Thesignature";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/signature_thesignature/Thesignature.ttf") format(opentype);
}

@font-face {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/signature_eb_garamond/EBGaramond-VariableFont_wght.ttf")
    format(opentype);
}

@font-face {
  font-family: "Crimson Pro";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/signature_crimson_pro/CrimsonPro-VariableFont_wght.ttf")
    format(opentype);
}
