.sidebar_nav_link {
  padding: spacer(1) spacer(2);

  &:hover {
    background-color: var(--tertiary-bg);
  }
}

.drawer_nav {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  box-shadow: var(--box-shadow);

  &--end {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
}
