.accordion {
  box-shadow: var(--box-shadow-sm);
  border-top-left-radius: var(--accordion-border-radius);
  border-top-right-radius: var(--accordion-border-radius);
  border-bottom-right-radius: var(--accordion-border-radius);
  border-bottom-left-radius: var(--accordion-border-radius);
}

.accordion-button {
  font-size: inherit;
  outline: 0;
}
