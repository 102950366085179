@function spacer($size) {
  @return map-get($spacers, $size);
}

@mixin scrim(
  $direction: to top,
  $color: $black,
  $alpha-from: 0.9,
  $alpha-to: 0
) {
  $diff: $alpha-from - $alpha-to;

  background: linear-gradient(
    $direction,
    rgba($color, $alpha-from) 0%,
    rgba($color, $alpha-from - $diff * 0.7) 50%,
    rgba($color, $alpha-from - $diff * 0.85) 65%,
    rgba($color, $alpha-from - $diff * 0.925) 75.5%,
    rgba($color, $alpha-from - $diff * 0.963) 82.85%,
    rgba($color, $alpha-from - $diff * 0.981) 88%,
    rgba($color, $alpha-to) 100%
  );
}

.scrim {
  position: relative;
  overflow: hidden;

  > * {
    z-index: 1;
    position: relative;
  }

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;

    @include scrim();
  }
}

.scrim-left {
  @extend .scrim;

  &::before {
    @include scrim($direction: to right);
  }
}

.scrim-right {
  @extend .scrim;

  &::before {
    @include scrim($direction: to left);
  }
}

:root {
  --spacer-1: #{spacer(1)};
  --spacer-2: #{spacer(2)};
  --spacer-3: #{spacer(3)};
  --spacer-4: #{spacer(4)};
  --spacer-5: #{spacer(5)};
}

@for $i from 1 through 5 {
  .outdent-#{$i} {
    margin-left: -1 * spacer($i);
    margin-right: -1 * spacer($i);
    width: calc(100% + (2 * #{spacer($i)}));
  }

  @each $breakpoint, $_width in $grid-breakpoints {
    .outdent-#{$breakpoint}-#{$i} {
      @include media-breakpoint-up($breakpoint) {
        margin-left: -1 * spacer($i);
        margin-right: -1 * spacer($i);
        width: calc(100% + (2 * #{spacer($i)}));
      }
    }
  }
}

@for $i from 1 through 4 {
  .max-lines-#{$i} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 1.5rem * $i;
    -webkit-line-clamp: #{$i};
    -webkit-box-orient: vertical;
  }
}

@mixin offset-icon {
  .icon {
    transform: translateY($icon-offset);
  }
}

@each $name, $color in $grays {
  .text-gray-#{$name} {
    color: $color !important;
  }
}

@each $breakpoint, $container-max-width in $container-max-widths {
  .mw-container-#{$breakpoint} {
    max-width: $container-max-width;
  }

  .minw-container-#{$breakpoint} {
    min-width: $container-max-width;
  }
}

.minw-0 {
  min-width: 0;
}

.fs-sm {
  font-size: $font-size-sm;
}

.fs-lg {
  font-size: $font-size-lg;
}

.flex-grow {
  flex-grow: 1;
}

.flex-even {
  flex: 1 1 0;
  width: 0;
}

.surface-1 {
  background-color: var(--surface-1);
}

.surface-2 {
  background-color: var(--surface-2);
}

.surface-3 {
  background-color: var(--surface-3);
}

.surface-4 {
  background-color: var(--surface-4);
}

.text-muted {
  color: var(--text-muted);
}

.text-subtle {
  color: var(--text-subtle);
}

.text-shadow {
  text-shadow:
    1px 0px 1px rgba($black, 0.15),
    0px 1px 1px rgba($black, 0.05),
    2px 1px 1px rgba($black, 0.15),
    1px 2px 1px rgba($black, 0.05),
    3px 2px 1px rgba($black, 0.15),
    2px 3px 1px rgba($black, 0.05);
}

@mixin color_utils($name, $color) {
  $color_muted: rgba($color, $muted_opacity);
  $color_subtle: rgba($color, $subtle_opacity);

  .text-#{$name},
  .color-#{$name} {
    color: $color !important;
  }

  .text-#{$name}-muted,
  .color-#{$name}-muted {
    color: $color_muted !important;
  }

  .text-#{$name}-subtle,
  .color-#{$name}-subtle {
    color: $color_subtle !important;
  }

  .bg-#{$name} {
    background-color: $color !important;
  }

  .bg-#{$name}-muted {
    background-color: $color_muted !important;
  }

  .bg-#{$name}-subtle {
    background-color: $color_subtle !important;
  }

  .border-#{$name} {
    border-color: $color !important;
  }

  .border-#{$name}-muted {
    border-color: $color_muted !important;
  }

  .border-#{$name}-subtle {
    border-color: $color_subtle !important;
  }
}

@each $collection in $color_collections {
  @each $name, $color in $collection {
    @include color_utils($name, $color);
  }
}

@each $name, $color in $theme-colors {
  @include color_utils($name, $color);
}
