.signature-preview {
  border-left: 2px solid #3f51b5;
  background-color: #3f51b50c;
  padding-top: 2px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;

  .signature-name {
    margin: 0;
    font-size: 30px;
    line-height: 32px;
    z-index: 1;
  }

  .signature-id {
    margin: 0;
    margin-top: -3px;
    font-size: 12px;
    line-height: 10px;
    color: #6c757d;
  }
}
